body {
  color: #000;
  font-size: 18px;
  line-height: 1.3;
}

.onboarding {
  color: #000;
  #app { overflow: visible; }

  .w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
  }

  .w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  .w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both;
  }

  .w-checkbox-input {
    float: left;
    margin-bottom: 0px;
    margin-left: -20px;
    margin-right: 0px;
    margin-top: 4px;
    line-height: normal;
  }

  .w-checkbox-input--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px #3898ec;
  }

  .w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  .w-form-formradioinput--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  .w-form-formradioinput--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px #3898ec;
  }

  .w-form-formradioinput--inputType-custom.w--redirected-checked {
    border-top-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
  }

  body {
    font-family: Manrope, sans-serif;
    color: #000;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 36px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
  }

  p {
    margin-bottom: 10px;
    color: #000;
    font-size: 17px;
    line-height: 23px;
  }

  a {
    color: #000;
    text-decoration: underline;
  }

  ul {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  ol {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  li {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
  }

  img {
    display: inline-block;
    max-width: 100%;
  }

  blockquote {
    max-width: 620px;
    margin-bottom: 10px;
    padding: 0px 20px 0px 0px;
    border-left: 1px none #000;
    color: #0f6457;
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    text-align: left;
  }

  figure {
    margin-bottom: 10px;
    font-family: Roboto;
    color: #858585;
    font-size: 14px;
    font-weight: 500;
  }

  figcaption {
    margin-top: 5px;
    font-family: Inter;
    text-align: center;
  }

  .go-top {
    position: fixed;
    left: auto;
    top: auto;
    right: 20px;
    bottom: 20px;
    z-index: 99999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 55px;
    height: 55px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: #0061ff;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .go-top:hover {
    background-color: #3e78ff;
  }

  .go-top.w--current {
    background-color: #0061ff;
  }

  .go-top.w--current:hover {
    background-color: #338af3;
  }

  .page-content {
    position: relative;
  }

  .utility-page-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0d2481;
  }

  .utility-page-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 420px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .utility-page-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .section {
    padding: 100px 5%;
    background-color: #fff;
  }

  .section.green {
    background-color: #0f6457;
  }

  .section.green._80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.green._180px-top {
    padding-top: 180px;
  }

  .section._80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.honey {
    background-color: #ddf8d7;
  }

  .section.honey._180px-top {
    padding-top: 180px;
  }

  .section.white-smoke {
    background-color: #f6f6f4;
    color: #000;
  }

  .section.dark-green {
    background-color: #063e35;
  }

  .section.dark-green._50px {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section.almond {
    background-color: #feeecc;
  }

  .section._180px-top {
    padding-top: 180px;
  }

  .section._180px-top.mustard {
    background-color: #0f6457;
  }

  .section._180px-top.prussian-blue {
    background-color: #172a3a;
  }

  .section._180px-top.purple {
    background-color: #cab5fa;
  }

  .section._180px-top.black {
    background-color: #1e1919;
  }

  .section._180px-top.pink {
    background-color: #d6a2ad;
  }

  .section.blue {
    background-color: #0d2481;
  }

  .section.fullheight {
    height: 100vh;
  }

  .section.fullheight.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section.isabelline {
    background-color: #ede9e3;
  }

  .section._0px-top {
    padding-top: 0px;
  }

  .section.black {
    background-color: #1e1919;
    color: #fff;
  }

  .section.ghost-white {
    background-color: #f5f8ff;
  }

  .section.alice-blue {
    background-color: #e9f0f1;
  }

  .section.color {
    background-color: #f3f3f6;
  }

  .section.blackish {
    background-color: #242122;
    color: #fff;
  }

  .container {
    position: relative;
    z-index: 1;
    max-width: 1130px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .container.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .container._50-70-80-100 {
    max-width: 50%;
  }

  .container.wide {
    max-width: 1680px;
  }

  .container._70-80-100 {
    max-width: 70%;
  }

  .hamburger-line {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 3px;
    background-color: #000;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .main-nav_nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-size: 16px;
    text-decoration: none;
  }

  .main-nav_nav-link:hover {
    opacity: 0.6;
  }

  .main-nav_nav-link.flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .main-nav_nav-link.flex.small.white {
    color: #fff;
  }

  .main-nav_nav-link.small {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
  }

  .menu-button {
    width: 50px;
    height: 50px;
  }

  .main-nav_dropdown_list {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
    background-color: #fff;
  }

  .main-nav_dropdown_list.w--open {
    position: absolute;
    z-index: 99999;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.07);
  }

  .main-nav_dropdown-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 220px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    font-weight: 600;
  }

  .main-nav_dropdown-link:hover {
    background-color: #f5f5f7;
    color: #000;
  }

  .main-nav_dropdown-link.w--current {
    color: rgba(0, 0, 0, 0.6);
  }

  .arrow {
    position: relative;
    width: 12px;
    margin-left: 3px;
  }

  .arrow.white {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .nav-link_link-text {
    line-height: 1.3;
    font-weight: 700;
  }

  .nav-link_link-text.remove-weight {
    font-weight: 600;
  }

  .button {
    display: inline-block;
    padding: 19px 22px;
    border-radius: 10px;
    background-color: #0061ff;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
  }

  .button:hover {
    background-color: #3e78ff;
    background-image: none;
  }

  .button.subscribe {
    position: absolute;
    left: auto;
    top: 6px;
    right: 8px;
    bottom: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .button.blue {
    background-color: #0061ff;
  }

  .button.blue:hover {
    background-color: #1773e2;
  }

  .button.small {
    min-width: 150px;
    padding: 13px 15px;
    font-size: 14px;
    text-align: center;
  }

  .button.full {
    width: 100%;
    text-align: center;
  }

  .button.whiter {
    background-color: #fff;
    color: #0061ff;
  }

  .button.whiter:hover {
    opacity: 0.8;
  }

  .button._200px {
    min-width: 200px;
  }

  .button._300px {
    min-width: 300px;
    text-align: center;
  }

  .button.empty {
    background-color: transparent;
    color: #0061ff;
  }

  .button.light-gray {
    background-color: #f5f5f7;
    color: #0061ff;
  }

  .margin-20px {
    margin-top: 20px;
  }

  .follow-link {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: transparent;
    text-align: center;
  }

  .margin-30px {
    margin-top: 30px;
  }

  .margin-30px.hidden {
    display: none;
  }

  .error-message {
    padding: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(191, 72, 0, 0.3);
    border-radius: 10px;
    background-color: #ffe8d9;
    color: #bf4800;
    text-align: center;
  }

  .search-result-item {
    margin-bottom: 20px;
    padding: 46px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 10px;
    background-color: #fff;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .search-result-item:hover {
    box-shadow: 0 18px 25px 0 rgba(0, 0, 0, 0.05);
  }

  .search-title {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 26px;
    line-height: 32px;
    text-decoration: none;
  }

  .search-link {
    margin-top: 4px;
    color: #888;
    font-weight: 700;
  }

  .search-result-wrapper {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .hero {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 100px 5%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0052b5;
  }

  .hero.white {
    overflow: hidden;
    background-color: #fff;
    color: #000;
  }

  .hero.black {
    background-color: #1e1919;
    color: #fff;
  }

  .left-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .right-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .right-flex.mobile {
    display: none;
  }

  .fixed-search {
    margin-right: auto;
    margin-left: auto;
  }

  .fixed-search._320px {
    max-width: 420px;
  }

  .search-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 10px 10px 18px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 15px;
    background-color: #f5f5f7;
    color: #111;
  }

  .search-block.white-background {
    background-color: #fff;
  }

  .search-input {
    margin-bottom: 0px;
    padding-left: 32px;
    float: left;
    border: 1px none #000;
    background-color: transparent;
    background-image: url('../images/search-outline.svg');
    background-position: 0% 50%;
    background-size: 22px;
    background-repeat: no-repeat;
    color: #111;
    font-size: 20px;
    line-height: 42px;
    font-weight: 400;
  }

  .search-input:focus {
    border-bottom-color: #fff;
  }

  .search-input::-webkit-input-placeholder {
    color: #969696;
    font-weight: 500;
  }

  .search-input:-ms-input-placeholder {
    color: #969696;
    font-weight: 500;
  }

  .search-input::-ms-input-placeholder {
    color: #969696;
    font-weight: 500;
  }

  .search-input::placeholder {
    color: #969696;
    font-weight: 500;
  }

  .search-button {
    padding: 16px 24px;
    border-radius: 10px;
    background-color: #063e35;
    color: #fff;
    font-size: 15px;
  }

  .search-button:hover {
    background-color: #0f6457;
  }

  .collection-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .collection-list.center-list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .categories-link {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 11px 15px;
    border-radius: 6px;
    background-color: #f5f5f7;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #000;
    text-decoration: none;
  }

  .categories-link:hover {
    opacity: 0.49;
  }

  .top-text {
    font-size: 14px;
    font-weight: 700;
  }

  .hero-grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .hero-grid.with-image {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1.2fr minmax(220px, 1fr);
    grid-template-columns: 1.2fr minmax(220px, 1fr);
  }

  .hero-grid.with-image.left {
    -ms-grid-columns: minmax(220px, 1fr) 1.2fr;
    grid-template-columns: minmax(220px, 1fr) 1.2fr;
  }

  .hero-grid._3-columns {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .hero-photo {
    position: relative;
    overflow: hidden;
    width: 95%;
    min-height: 750px;
    border-radius: 250px;
    background-color: #fff;
    -webkit-transform: skew(-7deg, 0deg);
    -ms-transform: skew(-7deg, 0deg);
    transform: skew(-7deg, 0deg);
  }

  .hero-photo.third {
    min-height: 450px;
  }

  .hero-photo.third.add-top {
    top: 80px;
  }

  .display-1 {
    font-size: 3.052rem;
    line-height: 1.2;
    font-weight: 600;
  }

  .display-1._70 {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .display-1.bloody {
    color: #58132b;
  }

  .display-1.light-yellow {
    color: #f7cc9c;
  }

  .display-1.no-margin {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .display-1.blue {
    color: #0d2481;
  }

  .display-1.maroon {
    color: #58132b;
  }

  .display-1.blackish {
    color: #242122;
  }

  .subhead {
    font-size: 25px;
    line-height: 1.3;
    font-weight: 500;
  }

  .subhead.white {
    color: #fff;
  }

  .inside-photo {
    position: absolute;
    left: -5%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 110%;
    margin-right: auto;
    margin-left: auto;
    background-image: url('../images/photo-1.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: skew(7deg, 0deg);
    -ms-transform: skew(7deg, 0deg);
    transform: skew(7deg, 0deg);
  }

  .inside-photo._5 {
    left: -25%;
    width: 150%;
    background-image: url('../images/photo-6.jpg');
    background-position: 50% 50%;
    background-attachment: scroll;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .inside-photo._5.second-photo {
    background-image: url('../images/photo-7.jpg');
    background-position: 100% 50%;
  }

  .subscribe-field {
    height: 65px;
    margin-bottom: 0px;
    padding-left: 47px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border: 1px none #000;
    border-radius: 10px;
    background-image: url('../images/icons8-male-user-144-1.png');
    background-position: 3% 50%;
    background-size: 26px;
    background-repeat: no-repeat;
  }

  .subscribe-field::-webkit-input-placeholder {
    color: #686868;
    font-size: 15px;
    font-weight: 600;
  }

  .subscribe-field:-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
    font-weight: 600;
  }

  .subscribe-field::-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
    font-weight: 600;
  }

  .subscribe-field::placeholder {
    color: #686868;
    font-size: 15px;
    font-weight: 600;
  }

  .subscribe-field.no-icon {
    padding-left: 20px;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
  }

  .success-message {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 201, 167, 0.5);
    border-radius: 10px;
    background-color: #ddf8d7;
    color: #063e35;
  }

  .subscribe-form {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 420px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .subscribe-form.for-footer {
    width: 100%;
  }

  .subscribe-form.in-coming {
    max-width: 420px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .margin-40px {
    margin-top: 40px;
  }

  .checkbox-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
  }

  .checkbox-field.center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .checkbox-field.no-margin {
    margin-bottom: 0px;
  }

  .checkbox {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 5px;
    background-color: #fff;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
  }

  .checkbox.w--redirected-checked {
    background-color: #3e78ff;
    background-size: 11px;
  }

  .checkbox.w--redirected-focus {
    box-shadow: none;
  }

  .checkbox-label {
    margin-bottom: 0px;
    color: #1c1c1c;
    font-weight: 600;
  }

  .margin-15px {
    margin-top: 15px;
  }

  .margin-60px {
    margin-top: 60px;
  }

  .display-2 {
    margin-top: 10px;
    color: #000;
    font-size: 2.441rem;
    line-height: 1.3;
    font-weight: 600;
  }

  .display-2.white {
    color: #fff;
  }

  .display-2.white.no-margin {
    font-weight: 600;
  }

  .display-2.no-margin {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .display-2.apricot {
    color: #ffc482;
  }

  .display-2.blue {
    color: #0d2481;
  }

  .features-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .features-grid._5-col {
    grid-column-gap: 70px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .features-icon {
    width: 50px;
    margin-right: auto;
    margin-left: auto;
  }

  .margin-80px {
    margin-top: 80px;
  }

  .grid-2-columns {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 150px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 0.75fr;
    grid-template-columns: 1fr 0.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .margin-50px {
    margin-top: 50px;
  }

  .statistics-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .statistics-block.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .statistic-number {
    margin-right: 16px;
    font-size: 50px;
    line-height: 50px;
  }

  .statistic-number.biger {
    margin-right: 0px;
    margin-bottom: 15px;
    font-size: 70px;
    line-height: 70px;
  }

  .paragraph-no-margin {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 1.3;
  }

  .align-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .align-center._80 {
    width: 80%;
  }

  .align-center.stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .white {
    color: #fff;
  }

  .paragraph-small {
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 1.3;
  }

  .paragraph-small.white.add-top {
    margin-top: 10px;
  }

  .paragraph-small.blue {
    color: #0061ff;
  }

  .paragraph-small.blue {
    color: #0061ff;
  }

  .paragraph-small.normal {
    font-weight: 400;
  }

  .paragraph-small.orange {
    color: #bf4800;
  }

  .display-3 {
    margin-top: 0px;
    font-size: 1.953rem;
    line-height: 1.3;
    font-weight: 600;
  }

  .display-3.add-left {
    margin-left: 15px;
  }

  .blog-post {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .blog-link {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    text-decoration: none;
  }

  .blog-link:hover {
    color: #0f6457;
  }

  .blog-title {
    margin-top: 10px;
    font-size: 27px;
    line-height: 33px;
  }

  .meta-tag-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .meta-tag-flex.center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .line-divider {
    width: 25px;
    height: 1px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #000;
  }

  .line-divider.transpraent {
    width: 10px;
    background-color: transparent;
  }

  .line-divider.green-line {
    background-color: #0f6457;
  }

  .line-divider.gray {
    display: block;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .line-divider.gray.mobile {
    display: none;
  }

  .line-divider.gray.margin-vertical-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .image-rounded {
    border-radius: 15px;
  }

  .testimonials-name {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .big-paragraph {
    font-size: 21px;
    line-height: 26px;
  }

  .big-paragraph.white.no-margin {
    margin-bottom: 0px;
    line-height: 1.3;
  }

  .footer-1 {
    padding: 60px 5% 20px;
    background-color: #111;
  }

  .display-5 {
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 600;
  }

  .footer-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    -ms-grid-columns: 2.5fr 1fr 1fr 0.5fr;
    grid-template-columns: 2.5fr 1fr 1fr 0.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .footer-link {
    margin-bottom: 15px;
    opacity: 0.5;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
  }

  .footer-link:hover {
    opacity: 1;
  }

  .footer-link.stores {
    opacity: 0.7;
  }

  .footer-link.stores:hover {
    opacity: 1;
  }

  .footer-link.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-style: normal;
  }

  .footer-flex.hidden {
    display: none;
  }

  .footer-line {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: hsla(0, 0%, 100%, 0.1);
  }

  .copyright-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .copyright {
    margin-bottom: 0px;
    color: #fff;
    font-size: 14px;
    line-height: 1.3;
  }

  .social-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 15px;
  }

  .social-wrapper.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .social-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    height: 32px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .social-icon:hover {
    opacity: 0.5;
  }

  .social-icon._2 {
    height: 35px;
    opacity: 0.7;
  }

  .social-icon._2:hover {
    opacity: 1;
  }

  .cookies-modal {
    position: fixed;
    left: 20px;
    top: auto;
    right: auto;
    bottom: 20px;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    max-width: 400px;
    padding: 22px 52px 22px 22px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: #000;
  }

  .cookies-parahraph {
    margin-bottom: 15px;
    color: #fff;
    font-size: 15px;
  }

  .close-button {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 38px;
    height: 38px;
    margin-left: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #222;
    cursor: pointer;
  }

  .line {
    position: absolute;
    width: 15px;
    height: 2px;
    border-radius: 20px;
    background-color: #fff;
  }

  .line.first {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .line.second {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .announcement-text {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
  }

  .display-6 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 700;
  }

  .photo-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .margin-160px {
    margin-top: 160px;
  }

  .fun-grid {
    display: -ms-grid;
    display: grid;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .field {
    height: 60px;
    padding-left: 14px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 10px;
    font-size: 15px;
  }

  .field::-webkit-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .field:-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .field::-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .field::placeholder {
    color: #686868;
    font-size: 15px;
  }

  .field.area {
    height: 120px;
    padding-top: 11px;
  }

  .field.left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .field.left::-webkit-input-placeholder {
    line-height: 15px;
  }

  .field.left:-ms-input-placeholder {
    line-height: 15px;
  }

  .field.left::-ms-input-placeholder {
    line-height: 15px;
  }

  .field.left::placeholder {
    line-height: 15px;
  }

  .form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .heading-icon {
    width: 70px;
    height: 70px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    background-image: url('../images/icons8-alarm-144.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .list-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .faq-icon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .faq-top-part {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(41, 51, 92, 0.15);
    background-color: #fff;
  }

  .faq-title-content {
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .faq-title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
  }

  .stripe-1 {
    width: 2px;
    height: 100%;
    background-color: #000;
  }

  .faq-plus-icon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 16px;
    height: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .faq-content {
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 0px;
  }

  .faq-item {
    overflow: hidden;
    -webkit-perspective-origin: 50% 0%;
    perspective-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  .faq-basic {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .faq-wrapper {
    overflow: hidden;
    line-height: 1.6em;
    cursor: pointer;
  }

  .stripe-2 {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
  }

  .cta-grid {
    display: -ms-grid;
    display: grid;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    -ms-grid-columns: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .align-right {
    text-align: right;
  }

  .nav-menu_dropdown {
    display: block;
  }

  .case-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .case-wrapper {
    overflow: hidden;
    width: 100%;
    border-radius: 7px;
    text-decoration: none;
  }

  .case-content {
    padding-top: 30px;
    padding-right: 15px;
  }

  .case-flex-small {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 80px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .client-image {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .margin-10px {
    margin-top: 10px;
  }

  .margin-10px.hidden {
    display: none;
  }

  .small-tag {
    margin-left: 20px;
    padding: 7px 19px;
    border-radius: 30px;
    background-color: #fff;
    color: #0061ff;
    font-size: 13px;
    text-decoration: none;
  }

  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tab-icon {
    width: 34px;
    margin-right: 10px;
  }

  .display-4 {
    margin-top: 0px;
    font-size: 1.563rem;
    line-height: 1.3;
    font-weight: 600;
  }

  .paragraph {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
  }

  .paragraph.blue {
    color: #0061ff;
  }

  .paragraph.bold {
    font-weight: 700;
  }

  .client-circle {
    position: absolute;
    left: 68px;
    top: 35px;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 70px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    background-color: #fff;
  }

  .client-circle._2 {
    left: auto;
    top: 20px;
    right: 20px;
    bottom: auto;
    width: 100px;
    height: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .top-small-title {
    font-size: 18px;
  }

  .top-small-title.white {
    color: #fff;
  }

  .top-small-title._0px {
    margin-bottom: 0px;
  }

  .top-small-title.blue {
    color: #0052b5;
  }

  .margin-100px {
    margin-top: 100px;
  }

  .case-inside-grid {
    display: -ms-grid;
    display: grid;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 30px;
  }

  .case-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 480px;
    padding: 55px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    text-decoration: none;
  }

  .case-1:hover {
    color: #063e35;
  }

  .case-1.case-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .case-1.grey {
    background-color: #414147;
  }

  .case-left {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f8ff;
  }

  .case-left.voice {
    background-image: url('../images/02_Roaming-Voice.svg');
    background-size: auto;
  }

  .case-left.sim-cards {
    background-image: url('../images/05_SIM.svg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .case-left.alwayson {
    background-image: url('../images/06_Always-on-connectivity.svg');
  }

  .case-left.roaming-sms {
    background-image: url('../images/03_Roaming-SMS.svg');
  }

  .case-left.enterprise {
    background-image: url('../images/01.svg');
  }

  .case-left.honeydew {
    background-image: url('../images/05_Bg_SIM.svg');
    background-position: 0px 0px;
    background-size: auto;
  }

  .case-left.pink {
    background-image: url('../images/06_Bg_Always-on-connectivity.png');
    background-position: 0px 0px;
    background-size: auto;
  }

  .case-left.rose {
    background-image: url('../images/04_Bg_Virtual-Mobile-Numbers.svg');
    background-position: 0px 0px;
    background-size: auto;
  }

  .case-left.mobile {
    background-image: url('../images/01_Roaming-Internet.svg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .features-2-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 70px;
    grid-row-gap: 50px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .featurs-illustration-icon {
    width: 80px;
  }

  .featurs-illustration-icon.wider {
    width: 95px;
  }

  .author-circle {
    width: 58px;
    height: 58px;
    margin-right: 8px;
    border-radius: 100%;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .author-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }

  .pargaraph-author {
    margin-bottom: 0px;
    margin-left: 7px;
    color: #fff;
    font-size: 16px;
  }

  .pargaraph-author.green {
    color: #0f6457;
  }

  .hero-margin {
    margin-top: 60px;
  }

  .hero-margin.coming {
    margin-top: 0px;
    text-align: center;
  }

  .sub-hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 200px 5% 80px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0f6457;
  }

  .sub-hero.about-2 {
    padding-bottom: 80px;
    background-color: #ddf8d7;
  }

  .sub-hero.careers-color {
    padding-bottom: 80px;
    background-color: #0f6457;
  }

  .sub-hero.light-blue {
    background-color: #b4d0e7;
  }

  .sub-hero.honeydew {
    background-color: #ddf8d7;
  }

  .sub-hero.pink {
    background-color: #f5cccb;
  }

  .sub-hero.rose {
    background-color: #f9decf;
  }

  .sub-hero.yellow {
    background-color: #ffc482;
  }

  .sub-hero.blackish {
    background-color: #242122;
  }

  .sign-font {
    font-family: Tuesdaynight;
    color: #0f6457;
    font-size: 50px;
    line-height: 50px;
    font-weight: 400;
  }

  .team-single-grid {
    display: -ms-grid;
    display: grid;
    overflow: hidden;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 10px;
  }

  .team-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 400px;
    padding: 59px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    text-align: left;
  }

  .tags-list {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .legal-wrapper {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: left;
  }

  .big-social-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .big-social-wrapper.left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .social-circle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 70px;
    margin-right: 5px;
    margin-left: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
  }

  .social-circle.facebook {
    background-color: #1877f2;
  }

  .social-circle.twitter {
    background-color: #1da1f2;
  }

  .social-circle.youtube {
    background-color: red;
  }

  .social-circle.instagram {
    background-color: #c32aa3;
  }

  .social-circle.slack {
    background-color: #4a154b;
  }

  .contact-1-wrapper {
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
  }

  .careers-page-wrapper {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }

  .author-heading-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .blog-post-wrapper {
    width: 65%;
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
  }

  .industry-grid {
    display: -ms-grid;
    display: grid;
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .client-title {
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
  }

  .white-transprarent {
    color: hsla(0, 0%, 100%, 0.6);
  }

  .website-link {
    color: hsla(0, 0%, 100%, 0.6);
    font-size: 17px;
    line-height: 23px;
  }

  .category-block-link {
    text-decoration: none;
  }

  .blog-page-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .author-image {
    width: 100%;
    height: 100%;
  }

  .case-image {
    width: 100%;
  }

  .modal-parent {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 999999;
    display: none;
    overflow: auto;
    width: 100%;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .modal-bg {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    overflow: auto;
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 60px;
    background-color: #fff;
  }

  .modal-centering {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .form-wrapper {
    display: block;
    width: 600px;
  }

  .list-bullet {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }

  .list-bullet._1st {
    padding-top: 0px;
  }

  .list-bullet.alt {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid rgba(81, 81, 84, 0.25);
    color: #515154;
    font-size: 17px;
    line-height: 23px;
  }

  .list-icon {
    width: 30px;
    margin-right: 15px;
  }

  .custom-label {
    position: relative;
    margin-bottom: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 18px;
    font-weight: 600;
  }

  .custom-label.small {
    color: #686868;
    font-size: 15px;
    line-height: 15px;
  }

  .custom-label._80 {
    width: 80%;
  }

  .form-nav-text {
    -webkit-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    color: #0061ff;
    font-size: 17px;
    line-height: 17px;
  }

  .form-nav-text:hover {
    color: #1773e2;
  }

  .custom-radio-field {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    height: 90px;
    margin-bottom: 15px;
    padding-left: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .custom-radio-field.right {
    height: 60px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .custom-radio-field.hidden {
    display: none;
  }

  .custom-radio-field.complicated {
    height: auto;
    min-height: 90px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .custom-radio {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
    margin-top: 0px;
    margin-left: 0px;
    border-style: none;
    border-radius: 10px;
    background-color: #fff;
    background-image: url('../images/radio_button_unchecked_black_24dp.svg');
    background-position: 97% 50%;
    background-size: 24px;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1px rgba(41, 51, 92, 0.2);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .custom-radio:hover {
    background-color: #fdfdfd;
  }

  .custom-radio.w--redirected-checked {
    border-style: none;
    border-width: 0px;
    border-radius: 10px;
    background-image: url('../images/radio_button_checked_black_24dp.svg');
    box-shadow: inset 0 0 0 3px #0061ff;
  }

  .custom-radio.right {
    height: 60px;
    border-style: solid solid solid none;
    border-color: rgba(41, 51, 92, 0.2);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-position: 90% 50%;
    box-shadow: none;
  }

  .custom-radio.right.w--redirected-checked {
    border-style: solid solid solid none;
    border-width: 1px;
  }

  .custom-radio.complicated {
    background-position: 97% 30px;
  }

  .textarea {
    min-height: 90px;
    padding-left: 14px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 10px;
  }

  .textarea::-webkit-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .textarea:-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .textarea::-ms-input-placeholder {
    color: #686868;
    font-size: 15px;
  }

  .textarea::placeholder {
    color: #686868;
    font-size: 15px;
  }

  .custom-togle {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 26px;
    line-height: 26px;
    cursor: pointer;
  }

  .togle-switch {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    width: 52px;
    height: 30px;
    margin-top: 0px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 20px;
    background-color: #e2e2e2;
    background-image: url('../images/black-circle.svg');
    background-position: 2px 50%;
    background-size: 24px;
    background-repeat: no-repeat;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .togle-switch.w--redirected-checked {
    border-color: #0052b5;
    background-color: #1773e2;
    background-image: url('../images/black-circle.svg');
    background-position: 24px 50%;
    background-size: 24px;
  }

  .togle-switch.w--redirected-focus {
    box-shadow: none;
  }

  .footer-logo {
    width: 70px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .form-fields-grid {
    grid-column-gap: 10px;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .custom-checkbox-label {
    position: absolute;
    left: 0%;
    top: auto;
    right: auto;
    bottom: auto;
    margin-bottom: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
  }

  .custom-checkbox-label.more-margin-left {
    margin-left: 45px;
  }

  .blue-dot {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    border-radius: 50%;
    background-image: linear-gradient(135deg, #83eaf1, #63a4ff);
  }

  .blue-dot.white {
    background-image: linear-gradient(135deg, #f5f7fa, #b8c6db);
  }

  .blue-dot.custom {
    background-image: linear-gradient(135deg, #fb7ba2, #f6f0c4);
  }

  .custom-label-caption {
    position: relative;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 400;
  }

  .toggle-image {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    border-radius: 5px;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 0px 0px;
    background-size: cover;
  }

  .toggle-image.lux {
    background-image: url('../images/Flag_of_Luxembourg_wide.svg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .toggle-image.uk {
    background-image: url('../images/Flag_of_the_United_Kingdom.svg');
    background-position: 50% 50%;
  }

  .custom-input-group {
    grid-column-gap: 0px;
    -ms-grid-columns: 1fr 0.35fr;
    grid-template-columns: 1fr 0.35fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .lottie-animation {
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
  }

  .bullet {
    width: 5px;
    height: 5px;
    margin-right: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
    background-color: #414147;
  }

  .body {
    font-family: Manrope, sans-serif;
    line-height: 1.3;
    font-weight: 600;
  }

  .subhead-1 {
    font-size: 25px;
    line-height: 32px;
    font-weight: 600;
  }

  .subhead-1.white {
    color: #fff;
  }

  .subhead-1.dim-gray {
    color: #414147;
  }

  .subhead-1.dim-gray.hidden {
    display: none;
  }

  .arrow-right {
    margin-left: 5px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 11px;
    line-height: 11px;
    text-decoration: none;
  }

  .illustration-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100px;
    margin-bottom: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .link-external {
    margin-left: 5px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }

  .inline-icon {
    margin-right: 10px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 14px;
    line-height: 14px;
  }

  .store-badge {
    max-width: 150px;
  }

  .footer-text {
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .footer-text._90 {
    width: 90%;
    font-size: 14px;
    line-height: 1.3;
  }

  .nav-heading {
    margin-bottom: 15px;
    color: #fff;
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
  }

  ._90 {
    width: 90%;
  }

  .footer-subscribe {
    margin-bottom: 0px;
  }

  .grid-image {
    width: 100%;
    border-radius: 10px;
  }

  .grid-image.smaller {
    width: 70%;
    height: auto;
  }

  .images-grid {
    position: relative;
    width: 800px;
    float: left;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }

  .logos-grid {
    width: 100%;
    float: right;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .message-badge {
    width: 500px;
  }

  .message-badge._30px-right {
    margin-right: 30px;
  }

  .message-badge._60px-right {
    margin-right: 60px;
  }

  .logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 140px;
    height: 140px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #f5f5f7;
    border-radius: 40px;
    background-color: transparent;
  }

  .logo-wrapper._30px-top {
    margin-top: 30px;
  }

  .bottom-cards-grid {
    margin-right: auto;
    margin-left: auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .bottom-card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 600px;
    padding: 100px 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
  }

  .bottom-card.cornsilk {
    background-color: #fdf8ea;
  }

  .bottom-card.peach-puff {
    background-color: #fbf5fe;
  }

  .bottom-card.honeydew {
    background-color: #eff7eb;
  }

  .bottom-card.light-steel-blue {
    background-color: #e8f1fa;
  }

  .link-block {
    text-decoration: none;
  }

  .services-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 50px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .service-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    text-decoration: none;
  }

  .messages-grid-left {
    width: 800px;
    float: right;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .form-modal {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 940px;
    margin-top: 20px;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 18px;
    background-color: #f5f5f7;
  }

  .form-modal.padding-bottom-30px {
    margin-bottom: 60px;
    padding-bottom: 30px;
  }

  .chevrone-back {
    margin-right: 8px;
    font-family: 'Fa solid 900', sans-serif;
    line-height: 16px;
  }

  .form-section-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .form-image {
    width: 520px;
  }

  .custom-checkbox-field-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    height: auto;
    min-height: 90px;
    margin-bottom: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .custom-checkbox-field-2.complicated {
    height: auto;
    min-height: 90px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .custom-checkbox-2 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
    margin-top: 0px;
    margin-left: 0px;
    border-style: none;
    border-width: 0px;
    border-radius: 10px;
    background-color: #fff;
    background-image: url('../images/check_box_outline_blank_black_24dp.svg');
    background-position: 97% 50%;
    background-size: 24px;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1px rgba(41, 51, 92, 0.2);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .custom-checkbox-2:hover {
    background-color: #fdfdfd;
  }

  .custom-checkbox-2.w--redirected-checked {
    width: auto;
    height: auto;
    border-style: none;
    border-width: 0px;
    border-radius: 10px;
    background-color: #fff;
    background-image: url('../images/check_box_black_24dp.svg');
    background-position: 97% 50%;
    background-size: 24px;
    background-attachment: scroll;
    box-shadow: inset 0 0 0 3px #0061ff;
  }

  .custom-checkbox-2.complicated {
    background-position: 97% 30px;
  }

  .custom-tab-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 90px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 15px 35px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(41, 51, 92, 0.2);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #686868;
    font-size: 18px;
  }

  .custom-tab-block:hover {
    background-color: #fafafa;
    color: #292929;
  }

  .custom-tab-block.w--current {
    background-color: #fff;
    box-shadow: inset 0 0 0 3px #0061ff, 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    color: #292929;
  }

  .custom-tab-block.last {
    margin-right: 0px;
  }

  .custom-tab-block.first {
    margin-left: 0px;
  }

  .custom-tab-block.first:hover {
    background-color: #fdfdfd;
  }

  .notification-wrapper {
    padding: 30px;
    border-radius: 15px;
    background-color: #f5f5f7;
  }

  .notification-wrapper.small {
    padding-bottom: 20px;
    border-radius: 10px;
  }

  .signup-tool-title {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 99;
    padding: 13px 2%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }

  .title-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .tabs-2 {
    position: static;
  }

  .input-slider {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    padding: 8px 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 10px;
    background-color: #fff;
    font-size: 17px;
    line-height: 17px;
    text-align: center;
  }

  .calculator-slider-input {
    display: none;
  }

  .ui-slider-handle {
    position: absolute;
    top: -191%;
    right: auto;
    width: 24px;
    height: 24px;
    margin-right: -10px;
    margin-left: -10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    cursor: ew-resize;
  }

  .div-block-info {
    position: relative;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100%;
  }

  .max {
    display: inline-block;
    float: right;
    color: #686868;
    font-size: 15px;
    line-height: 20px;
  }

  .ui-widget-content {
    position: relative;
    display: block;
    width: 100%;
    height: 5px;
    margin-top: 20px;
    float: none;
    border: 0px none #1b2128;
    border-radius: 5px;
    background-color: rgba(41, 51, 92, 0.2);
    background-image: -webkit-gradient(linear, left top, right top, from(#fad04a), color-stop(0%, #e5e5e5));
    background-image: linear-gradient(90deg, #fad04a, #e5e5e5 0%);
  }

  .field-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    margin-bottom: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    clear: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
  }

  .min {
    display: inline-block;
    float: left;
    color: #686868;
    font-size: 15px;
    line-height: 20px;
  }

  .div-block-3 {
    position: relative;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
  }

  .div-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .gray {
    color: #acacac;
  }

  .notification-text {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .notification-text p {
    font-size: 15px;
    line-height: 20px;
  }

  .margin-5px {
    margin-top: 5px;
  }

  .hidden-content {
    display: none;
    margin-bottom: 15px;
  }

  .dimmed {
    color: #acacac;
  }

  .custom-label-extra-content {
    position: relative;
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  ._3-col {
    margin-top: 15px;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    grid-template-areas: ".";
    -ms-grid-columns: 1.5fr 0px 0.75fr 0px 0.75fr;
    grid-template-columns: 1.5fr 0.75fr 0.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  ._2-col {
    margin-top: 15px;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  ._2-col.no-margin {
    margin-top: 0px;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
  }

  .arrow-down {
    margin-left: 5px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 11px;
    line-height: 11px;
    text-decoration: none;
  }

  .inline-link {
    color: #0061ff;
  }

  .bullet-text {
    margin-right: 15px;
    padding-top: 3px;
    font-size: 15px;
    line-height: 1;
  }

  .scrollable-content {
    overflow: auto;
    height: 500px;
    padding: 20px 20px 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(41, 51, 92, 0.2);
    border-radius: 15px;
    background-color: #fff;
  }

  .gtc p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .download {
    margin-left: 5px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    text-decoration: none;
  }

  .form-name {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 21px;
    line-height: 1.3;
    font-weight: 600;
  }

  .form-name.mobile {
    display: none;
  }

  .form-price {
    font-size: 21px;
    line-height: 1.3;
    font-weight: 600;
  }

  .modal-nav-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #f5f5f7;
  }

  .modal-nav-wrapper.justify-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .back-1 {
    position: static;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-width: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    text-decoration: none;
  }

  .back-1:hover {
    opacity: 1;
  }

  .cancel-1 {
    position: static;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-width: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    text-decoration: none;
  }

  .cancel-1:hover {
    opacity: 1;
  }

  .logo-inverted {
    position: absolute;
    left: 50%;
    top: 40px;
    right: auto;
    bottom: auto;
    -webkit-filter: invert(100%);
    filter: invert(100%);
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .form-submit-grid {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .close {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-width: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    text-decoration: none;
  }

  .close:hover {
    opacity: 1;
  }

  .country-list {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 20px;
    column-gap: 20px;
  }

  .country-list p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .images-grid-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-3 {
    float: right;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .voice {
    -webkit-transform: translate(-40px, 0px);
    -ms-transform: translate(-40px, 0px);
    transform: translate(-40px, 0px);
  }

  .feature-image.stretch {
    width: 100%;
  }

  .feature-image.stretch.with-bg {
    padding: 40px;
    border-radius: 30px;
    background-color: #242122;
  }

  .subscrive-form.hidden {
    display: none;
  }

  .components-grid-style {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 150px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .components-grid-style.reverse {
    position: relative;
    -ms-grid-columns: 1fr 0.5fr;
    grid-template-columns: 1fr 0.5fr;
  }

  .sticky-module {
    position: relative;
    min-height: 600px;
  }

  .sticky-module.short {
    min-height: 400px;
  }

  .sticky-image {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
  }

  .sticky-modules-wrapper {
    position: relative;
  }

  .sticky-text {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    margin-bottom: 50px;
  }

  .sticky-text.last {
    position: static;
  }

  .more-features-grid {
    margin-bottom: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .more-features-grid.shift-right {
    -ms-grid-columns: 0.75fr 1fr;
    grid-template-columns: 0.75fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .more-features-grid.shift-left {
    -ms-grid-columns: 1fr 0.75fr;
    grid-template-columns: 1fr 0.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .features {
    margin-right: auto;
    margin-left: auto;
  }

  .features._80--100 {
    max-width: 80%;
  }

  .feature-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 50px 50px 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 30px;
    background-color: #242122;
  }

  .more-featurs-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .more-featurs-grid._80--100 {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .more-features {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  ._70--80--100 {
    max-width: 70%;
  }

  .more-features-icon {
    width: 60px;
    margin-right: 30px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .animation-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 250px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #0d2481;
  }

  .animation-wrapper.mobile {
    display: none;
  }

  .hero-card-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 50px 50px 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #202020;
  }

  .hero-wide {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .hero-wide.mobile {
    display: none;
  }

  .hero-wide.blackish {
    background-color: #242122;
  }

  .hero-wide.blackish.intro {
    padding-top: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #f6f6f4;
  }

  .accordion-wrapper {
    max-width: 100%;
  }

  .accordion {
    display: block;
    margin-bottom: -24px;
  }

  .accordion.js-accordion {
    margin-bottom: 20px;
  }

  .accordion-body__contents {
    opacity: 1;
    font-size: 18px;
    line-height: 1.3;
  }

  .accordion-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2e323c;
  }

  .accordion-header.js-accordion-header {
    cursor: pointer;
  }

  .question-text {
    margin-top: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #000;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
  }

  .accordion-body {
    display: block;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .accordion__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 20px 32px 20px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(41, 51, 92, 0.15);
    background-color: #fff;
    -webkit-transition: box-shadow 300ms ease;
    transition: box-shadow 300ms ease;
  }

  .accordion__item.js-accordion-item {
    padding-bottom: 20px;
  }

  .accordion__item.js-accordion-item.active {
    cursor: default;
  }

  .acordion-grid {
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .acordion-grid._100 {
    width: 100%;
    margin-left: 2%;
  }

  .acordion-animation-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 80px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .acordion-animation-wrapper.with-image-bg {
    position: relative;
    overflow: hidden;
    min-height: 750px;
  }

  .accordion-right-block {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .accordion-right-block.blue {
    background-color: #0d2481;
  }

  .accordion-right-block.red {
    background-color: #bf4800;
  }

  .accordion-right-block.pink {
    background-color: #d6a2ad;
  }

  .accordion-right-block.apricot {
    background-color: #ffc482;
  }

  .accordion-right-block.green {
    background-color: #b3efb2;
  }

  .accordion-right-block.salad {
    background-color: #b6c649;
  }

  .accordion-right-block.dark-green {
    background-color: #0f6457;
  }

  .accordion-image-wrapper {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background-color: #fdfbfd;
  }

  .accordion-image {
    max-height: 500px;
  }

  .accordion-image._400px {
    max-width: 400px;
    padding-bottom: 10px;
  }

  .accordion-image-overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    border-radius: 10px;
    box-shadow: inset -13px -16px 20px 30px #fdfbfd;
  }

  .accordion-image-overlay.small {
    box-shadow: inset 0 0 20px 20px #fdfbfd;
  }

  .accordion-text-wrapper {
    max-width: 500px;
  }

  .hero-card-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .hero-card-3.with-image {
    overflow: hidden;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f7;
  }

  .highlighted-text {
    display: inline-block;
    color: #fdfbfd;
    font-size: 2.441em;
    line-height: 1.3;
    font-weight: 600;
  }

  .highlighted-text.apricot {
    color: #ffc482;
  }

  .animated-text-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .animated-text-wrapper.hidden {
    display: none;
  }

  .image {
    width: 100px;
  }

  .pulse-button-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .pulse-button-wrapper.hidden {
    display: none;
  }

  .play-button {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    padding: 18px 20px 18px 28px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100%;
    background-color: #fa183d;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
  }

  .play-button:hover {
    background-color: #dd1233;
  }

  .play-button-pulse {
    position: absolute;
    left: 50%;
    top: 50%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: rgba(245, 20, 67, 0.5);
    -webkit-transform: translate(0px, -50%) translate(-50%, 0px);
    -ms-transform: translate(0px, -50%) translate(-50%, 0px);
    transform: translate(0px, -50%) translate(-50%, 0px);
  }

  .feature-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .accordion-item-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 20px 32px 20px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid rgba(41, 51, 92, 0.15);
    background-color: #fff;
    -webkit-transition: box-shadow 300ms ease;
    transition: box-shadow 300ms ease;
  }

  .body-3 {
    font-size: 18px;
  }

  .icon-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 60px;
    margin-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-image {
    max-width: 600px;
    border-style: solid;
    border-width: 1px;
    border-color: #242122;
    border-radius: 10px;
  }

  .slider-nav-item {
    height: 5px;
    background-color: #acacac;
  }

  .slider-nav-item.current {
    background-color: #1e1919;
  }

  .slider-nav-wrapper {
    padding: 24px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    background-color: #ede9e3;
  }

  .text-block {
    font-size: 1rem;
  }

  .hero-image-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    min-height: 450px;
    background-image: url('../images/01_Illustration.png');
    background-position: 50% 100%;
    background-size: cover;
  }

  .list-wrapper {
    overflow: hidden;
    padding-top: 20px;
    padding-left: 20px;
    border-left: 3px solid #f5cccb;
    opacity: 1;
  }

  .list-item {
    margin-bottom: 20px;
    color: #acacac;
  }

  .solution-icon {
    width: 150px;
  }

  .solution-icon.medium {
    width: 100px;
  }

  .specs-item {
    display: -ms-grid;
    display: grid;
    margin-bottom: 100px;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .specs-item.last {
    margin-bottom: 0px;
  }

  .sticky-left {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
  }

  .specs-content {
    display: block;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .spect-content img {
    border-bottom: 1px solid #ede9e3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .spect-content figcaption {
    margin-top: 0px;
    font-family: Manrope, sans-serif;
  }

  .spect-content figure {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #ede9e3;
    border-radius: 5px;
  }

  .spect-content ul {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 24px;
  }

  .spect-content em {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 8px;
    background-color: #e3e8f4;
    font-family: 'JetBrains Mono', sans-serif;
    color: #24335a;
    font-size: 15px;
    line-height: 1;
    font-style: normal;
    font-weight: 400;
  }

  .spect-content ol {
    padding-left: 24px;
  }

  .upper-nav_wrapper {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 46px;
    padding: 5px 2%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0061ff;
    color: #fff;
    text-decoration: none;
  }

  .upper-nav_columns {
    width: 100%;
    grid-column-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .whitepaper-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }

  .upper-nav_right-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navigation-link_icon-large {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .navigation-link_icon-large.white {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .navigation-link_icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }

  .navigation-link_icon.medium {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
  }

  .navigation-link_icon.medium.inverted {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .nav-component {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    background-color: #fff;
  }

  .nav-component.static {
    position: static;
  }

  .main-nav_nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .main-nav_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 2%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }

  .main-nav_left-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .main-nav_right-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .custom-nav-logo {
    width: 100px;
  }

  .main-nav_brand {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .visible-on-tablet-mobile {
    display: none;
  }

  .align-left {
    text-align: right;
  }

  .feature-grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .feature-grid.with-image {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    -ms-grid-columns: 1.2fr minmax(220px, 1fr);
    grid-template-columns: 1.2fr minmax(220px, 1fr);
  }

  .feature-grid.with-image.left {
    grid-column-gap: 100px;
    grid-row-gap: 50px;
    -ms-grid-columns: minmax(220px, 1fr) 1.2fr;
    grid-template-columns: minmax(220px, 1fr) 1.2fr;
  }

  .signup-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .total-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .total-wrapper.desktop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .careers-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .careers-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 33px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .careers-content-greed {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1.5fr 1fr;
    grid-template-columns: 1.5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .number {
    font-family: 'JetBrains Mono', sans-serif;
    font-size: 3.05rem;
  }

  @media screen and (min-width: 1280px) {
    .custom-checkbox-field-2.complicated {
      height: auto;
      min-height: 90px;
      padding-top: 30px;
      padding-right: 20px;
      padding-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .custom-checkbox-2 {
      background-position: 97% 30px;
    }

    .cad-image.shift-left {
      -webkit-transform: translate(-52px, 0px);
      -ms-transform: translate(-52px, 0px);
      transform: translate(-52px, 0px);
    }
  }

  @media screen and (min-width: 1920px) {
    .grid-image {
      width: auto;
      height: auto;
    }

    .images-grid {
      grid-row-gap: 0px;
    }

    .logos-grid {
      width: auto;
    }

    .message-badge {
      max-width: none;
    }

    .bottom-cards-grid {
      max-width: 1400px;
    }

    .messages-grid-left {
      width: auto;
      justify-items: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .div-block-2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
  }

  @media screen and (max-width: 991px) {
    body {
      font-size: 14px;
      line-height: 1.3;
    }

    blockquote {
      font-size: 40px;
      line-height: 40px;
    }

    .section.green._80px {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .section.green._180px-top {
      padding-top: 140px;
    }

    .section.honey._180px-top {
      padding-top: 120px;
      padding-bottom: 60px;
    }

    .section._180px-top {
      padding-top: 140px;
    }

    .container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .container._50-70-80-100 {
      max-width: 70%;
    }

    .container._70-80-100 {
      max-width: 80%;
    }

    .hamburger-line {
      height: 2px;
      background-color: #000;
    }

    .main-nav_nav-link {
      padding: 20px 5%;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .main-nav_nav-link.flex {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .menu-button.w--open {
      background-color: transparent;
    }

    .main-nav_dropdown_list.w--open {
      position: relative;
      padding-right: 0px;
      padding-left: 0px;
      border-radius: 0px;
      background-color: #f6f6f4;
      box-shadow: none;
    }

    .main-nav_dropdown-link {
      padding-right: 5%;
      padding-left: 5%;
    }

    .hero {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .right-flex {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }

    .right-flex.mobile {
      display: block;
    }

    .hero-grid.with-image {
      grid-column-gap: 50px;
      grid-row-gap: 50px;
    }

    .hero-grid.with-image.left {
      -ms-grid-rows: auto;
      grid-template-rows: auto;
    }

    .hero-grid._3-columns {
      padding-left: 0%;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .hero-photo {
      max-width: 70%;
      margin-right: auto;
      margin-left: auto;
    }

    .hero-photo.third.add-top {
      left: -76px;
      top: 57px;
    }

    .display-1._70 {
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
    }

    .inside-photo {
      left: -7%;
      width: 115%;
    }

    .inside-photo._5 {
      left: -7%;
      width: 115%;
    }

    .margin-40px._20px {
      margin-top: 20px;
    }

    .features-grid {
      grid-column-gap: 70px;
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .features-grid._5-col {
      grid-column-gap: 70px;
      grid-row-gap: 70px;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .grid-2-columns {
      grid-row-gap: 50px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .blog-post {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .copyright-flex {
      padding-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .copyright {
      margin-bottom: 0px;
    }

    .social-wrapper.vertical {
      margin-left: 0px;
      padding-left: 0px;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      border-left-style: none;
    }

    .margin-160px {
      margin-top: 100px;
    }

    .fun-grid {
      width: 100%;
    }

    .form {
      width: 50%;
    }

    .cta-grid {
      width: 100%;
    }

    .case-grid {
      grid-row-gap: 40px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .paragraph.bold {
      font-weight: 700;
    }

    .client-circle {
      left: 46px;
    }

    .case-inside-grid {
      grid-row-gap: 0px;
    }

    .case-1 {
      min-height: 420px;
    }

    .case-left {
      min-height: 500px;
    }

    .case-left.alwayson {
      background-position: 50% 50%;
      background-size: cover;
    }

    .features-2-grid {
      grid-column-gap: 70px;
      grid-row-gap: 70px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .hero-margin {
      margin-top: 20px;
    }

    .customers-margin {
      margin-top: 40px;
    }

    .sub-hero {
      padding-top: 130px;
    }

    .sub-hero.about-2 {
      padding-bottom: 50px;
    }

    .sub-hero.light-blue {
      padding-top: 200px;
    }

    .team-single-grid {
      max-width: 480px;
      margin-right: auto;
      margin-left: auto;
      grid-row-gap: 0px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .team-single-grid._100 {
      width: 100%;
      max-width: 100%;
    }

    .team-1 {
      min-height: 200px;
    }

    .legal-wrapper {
      width: 100%;
    }

    .blog-post-wrapper {
      width: auto;
    }

    .industry-grid {
      justify-items: start;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .message-badge {
      width: 100%;
    }

    .logo-wrapper {
      width: 100px;
      height: 100px;
      border-radius: 30px;
    }

    .bottom-cards-grid {
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .bottom-card {
      max-width: none;
    }

    .services-grid {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .messages-grid-left {
      width: auto;
    }

    .form-modal.padding-bottom-30px {
      min-width: 0px;
    }

    .signup-tool-title {
      padding-right: 5%;
      padding-left: 5%;
    }

    .ui-slider-handle {
      border-radius: 60px;
      -webkit-transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out, height 200ms ease-in-out, width 200ms ease-in-out;
      transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out, height 200ms ease-in-out, width 200ms ease-in-out;
    }

    .ui-slider-handle:hover {
      top: -400%;
      width: 40px;
      height: 40px;
      border-radius: 60px;
      background-color: #f7941e;
      box-shadow: 0 2px 4px -3px #000;
    }

    .images-grid-wrapper {
      overflow: hidden;
    }

    .grid-3 {
      width: 100%;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }

    .sms-grid-wrapper {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .voice {
      -webkit-transform: translate(-45px, 0px);
      -ms-transform: translate(-45px, 0px);
      transform: translate(-45px, 0px);
    }

    .cad-image.shift-left {
      -webkit-transform: translate(-50px, 0px);
      -ms-transform: translate(-50px, 0px);
      transform: translate(-50px, 0px);
    }

    .components-grid-style {
      grid-column-gap: 50px;
      -ms-grid-columns: 1fr 0.5fr;
      grid-template-columns: 1fr 0.5fr;
    }

    .components-grid-style.reverse {
      grid-column-gap: 50px;
      -ms-grid-columns: 0.5fr 1fr;
      grid-template-columns: 0.5fr 1fr;
    }

    .sticky-module {
      position: relative;
      top: 0px;
    }

    .features._80--100 {
      max-width: 100%;
    }

    .more-featurs-grid._80--100 {
      max-width: 100%;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto;
      grid-template-rows: auto;
    }

    ._70--80--100 {
      max-width: 80%;
    }

    .animation-wrapper {
      height: 180px;
      padding-left: 2%;
    }

    .animation-wrapper.desktop {
      padding-left: 5%;
    }

    .hero-card-1 {
      padding-right: 5%;
      padding-left: 5%;
    }

    .hero-wide.desktop {
      display: none;
    }

    .hero-wide.mobile {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .hero-wide.blackish.intro {
      padding-top: 100px;
    }

    .accordion {
      width: 83.33%;
      padding-right: 8px;
      padding-left: 8px;
    }

    .accordion.js-accordion {
      width: auto;
      margin-bottom: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }

    .accordion__item {
      padding-right: 2%;
      padding-left: 2%;
    }

    .accordion__item.js-accordion-item {
      padding-right: 2%;
      padding-bottom: 10px;
      padding-left: 2%;
    }

    .acordion-grid._100 {
      margin-left: auto;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .compare-line {
      margin-top: 0px;
    }

    .accordion-right-block {
      position: static;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .accordion-image-wrapper {
      padding: 10px;
    }

    .accordion-image {
      width: 300px;
      max-height: none;
    }

    .accordion-item-mobile {
      padding: 60px 5%;
    }

    .solution-icon {
      width: 100px;
    }

    .specs-item {
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .sticky-left {
      position: relative;
      top: 0px;
    }

    .upper-nav_columns {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .whitepaper-wrapper {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .upper-nav_right-flex.hidden-on-tablet {
      display: none;
    }

    .hidden-on-tablet-mobile {
      display: none;
    }

    .main-nav_nav-menu {
      overflow: auto;
      height: 100vh;
      padding-bottom: 200px;
      background-color: #fff;
    }

    .main-nav_wrapper {
      padding: 10px 5%;
    }

    .custom-nav-logo {
      width: 80px;
    }

    .visible-on-tablet-mobile {
      display: block;
    }

    .align-left {
      text-align: left;
    }

    .pading-horizontal._5 {
      padding-right: 5%;
      padding-left: 5%;
    }

    .feature-grid.with-image {
      grid-column-gap: 50px;
      grid-row-gap: 50px;
    }

    .feature-grid.with-image.left {
      grid-column-gap: 49px;
      -ms-grid-rows: auto;
      grid-template-rows: auto;
    }

    .total-wrapper {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }

    .total-wrapper.desktop {
      display: none;
    }

    .careers-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 767px) {
    blockquote {
      font-size: 34px;
      line-height: 34px;
    }

    .section {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .section.px-margin-on-mobile {
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }

    .container._50-70-80-100 {
      max-width: 80%;
    }

    .main-nav_nav-link {
      padding-right: 5%;
      padding-left: 5%;
    }

    .hero-grid.with-image {
      grid-row-gap: 80px;
      -ms-grid-columns: 1.2fr;
      grid-template-columns: 1.2fr;
    }

    .hero-grid.with-image.left {
      -ms-grid-columns: minmax(220px, 1fr);
      grid-template-columns: minmax(220px, 1fr);
    }

    .hero-photo {
      max-width: 85%;
      min-height: 650px;
      border-radius: 230px;
    }

    .hero-photo.third {
      min-height: 350px;
    }

    .hero-photo.third.add-top {
      left: 0px;
    }

    .subscribe-form.for-footer {
      max-width: none;
    }

    .display-2._70--80--100 {
      max-width: 100%;
    }

    .features-grid._5-col {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .align-center.left-on-mobile {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }

    .display-3.add-left {
      margin-left: 0px;
    }

    .blog-post {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .footer-grid {
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .copyright-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .copyright {
      margin-bottom: 5px;
    }

    .social-wrapper.vertical {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .fun-grid {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .form {
      width: 60%;
    }

    .cta-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .align-right.make-left {
      text-align: left;
    }

    .case-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .client-circle {
      left: 80px;
    }

    .case-inside-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .case-1 {
      min-height: auto;
    }

    .case-left {
      min-height: 400px;
    }

    .features-2-grid {
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .margin-device {
      margin-top: 20px;
    }

    .center-mobile {
      text-align: center;
    }

    .case-center {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .sub-hero.light-blue {
      padding-top: 160px;
    }

    .tags-list {
      width: 100%;
    }

    .author-heading-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .modal-bg {
      padding-bottom: 0px;
    }

    .modal-centering {
      padding: 50px 20px 0px;
    }

    .form-wrapper {
      width: 100%;
    }

    ._90 {
      width: 100%;
    }

    .services-grid {
      grid-row-gap: 30px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .form-modal {
      width: 100%;
      margin-top: 0px;
      padding: 0px 20px 50px;
      border-radius: 0px;
    }

    .modal-nav-wrapper {
      position: -webkit-sticky;
      position: sticky;
      padding: 20px 0px;
    }

    .modal-nav-wrapper.justify-right {
      top: 0px;
    }

    .back-1 {
      z-index: 6;
    }

    .cancel-1 {
      z-index: 6;
    }

    .close {
      z-index: 6;
    }

    .feature-image {
      padding-right: 5%;
      padding-left: 5%;
    }

    .feature-image.no-padding {
      width: 100%;
      padding-right: 0%;
      padding-left: 0%;
    }

    .more-features-grid.shift-right {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .more-features-grid.shift-left {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .hero-wide.blackish.intro {
      padding-top: 60px;
    }

    .accordion {
      width: 100%;
      margin-bottom: -16px;
    }

    .question-text {
      margin-top: 8px;
    }

    .accordion__item {
      padding-right: 24px;
      padding-left: 24px;
    }

    .feature-item {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }

    .hero-grid-left-block {
      padding-right: 5%;
      padding-left: 5%;
    }

    .specs-content {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .upper-nav_wrapper {
      display: none;
      padding-right: 3%;
      padding-left: 3%;
    }

    .nav-component {
      top: 0px;
    }

    .main-nav_brand {
      padding-left: 0px;
    }

    .feature-grid.with-image {
      grid-row-gap: 80px;
      -ms-grid-columns: 1.2fr;
      grid-template-columns: 1.2fr;
    }

    .feature-grid.with-image.left {
      -ms-grid-columns: minmax(220px, 1fr);
      grid-template-columns: minmax(220px, 1fr);
    }

    .careers-content-greed {
      -ms-grid-columns: 1.75fr 1fr;
      grid-template-columns: 1.75fr 1fr;
    }
  }

  @media screen and (max-width: 479px) {
    body {
      font-size: 12px;
    }

    .utility-page-content {
      width: auto;
    }

    .section {
      padding: 60px 5%;
    }

    .section.green._180px-top {
      padding-top: 120px;
    }

    .section._80px {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .section.almond._180px-top {
      padding-top: 130px;
    }

    .section._180px-top {
      padding-top: 120px;
    }

    .section._180px-top.pink {
      background-color: #d6a2ad;
    }

    .section.fullheight {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100vh;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .section._0px-top._0px-all-on-mobile {
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }

    .container {
      margin-left: 0px;
    }

    .container.flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }

    .container._50-70-80-100 {
      max-width: none;
    }

    .container._70-80-100 {
      max-width: 100%;
    }

    .main-nav_nav-link {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .main-nav_nav-link:hover {
      border-radius: 5px;
      background-color: #f5f5f7;
    }

    .main-nav_dropdown_list.w--open {
      width: 100%;
    }

    .button.subscribe.button-mobile {
      position: relative;
      top: 0px;
      right: 0px;
      width: 100%;
      margin-top: 10px;
      padding-top: 19px;
      padding-bottom: 19px;
    }

    .button._200px {
      width: 100%;
    }

    .button._200px.full-on-mobile {
      min-width: 0px;
    }

    .button._300px {
      width: 100%;
      min-width: 0px;
    }

    .button.full-on-mobile {
      width: 100%;
      text-align: center;
    }

    .margin-30px._0-on-mobile {
      margin-top: 0px;
    }

    .search-result-item {
      padding: 23px;
    }

    .search-title {
      font-size: 20px;
      line-height: 26px;
    }

    .search-result-wrapper {
      width: 100%;
    }

    .hero {
      padding: 113px 20px 25px;
    }

    .hero.white {
      padding-right: 20px;
      padding-left: 20px;
    }

    .left-flex {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .search-block {
      padding: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      border-radius: 0px;
      background-color: transparent;
    }

    .search-block.white-background {
      background-color: transparent;
    }

    .search-input {
      height: 55px;
      margin-bottom: 15px;
      padding-left: 42px;
      border-radius: 15px;
      background-color: #f5f5f7;
      background-position: 5% 50%;
      font-size: 18px;
    }

    .search-input.white-input {
      background-color: #fff;
    }

    .search-button {
      width: 100%;
    }

    .top-text {
      font-size: 14px;
    }

    .hero-grid.with-image {
      justify-items: center;
      grid-row-gap: 60px;
    }

    .hero-grid.with-image.left {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .hero-photo {
      max-width: 70%;
      min-height: 360px;
    }

    .hero-photo.third {
      max-width: 100%;
      min-height: 220px;
    }

    .subhead {
      font-size: 21px;
    }

    .subscribe-field {
      background-position: 5% 50%;
      background-size: 26px;
    }

    .subscribe-form {
      width: 100%;
    }

    .subscribe-form.for-footer {
      width: 100%;
    }

    .margin-40px {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }

    .checkbox {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }

    .display-2.white.no-margin.smaller-on-mobile {
      font-size: 24px;
      line-height: 32px;
    }

    .features-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .features-grid._5-col {
      grid-row-gap: 70px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .margin-80px._50px {
      margin-top: 50px;
    }

    .grid-2-columns {
      grid-row-gap: 30px;
    }

    .margin-50px._30px {
      margin-top: 30px;
    }

    .statistics-block {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }

    .statistic-number {
      margin-right: 0px;
      margin-bottom: 20px;
      padding-right: 0px;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
      border-right-style: none;
    }

    .align-center {
      width: 100%;
    }

    .meta-tag-flex.reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .meta-tag-flex.reverse.left {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .meta-tag-flex.verical {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .line-divider.transpraent {
      width: 0px;
      height: 20px;
    }

    .line-divider.none {
      width: 0px;
      height: 5px;
      background-color: transparent;
    }

    .line-divider.green-line {
      width: 0px;
      height: 20px;
    }

    .line-divider.gray {
      display: block;
    }

    .line-divider.gray.mobile {
      display: block;
    }

    .big-paragraph {
      font-size: 19px;
      line-height: 23px;
    }

    .display-5 {
      margin-top: 10px;
    }

    .footer-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .footer-link.flex {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .copyright-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .social-wrapper.vertical {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .social-icon._2 {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .cookies-modal {
      left: 10px;
      right: 10px;
      bottom: 10px;
    }

    .display-6 {
      margin-top: 10px;
    }

    .fun-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .form {
      width: 100%;
    }

    .small-tag {
      margin-right: 0px;
    }

    .tabs-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .client-circle {
      left: 20%;
    }

    .case-1 {
      min-height: auto;
      padding: 33px 22px 22px;
    }

    .features-2-grid {
      grid-row-gap: 70px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .author-flex {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .pargaraph-author.green.hide {
      display: none;
    }

    .hero-margin {
      margin-top: 0px;
    }

    .customers-margin {
      margin-top: 20px;
    }

    .sub-hero.careers-color {
      padding-bottom: 50px;
    }

    .sign-font {
      font-size: 40px;
      line-height: 40px;
    }

    .team-single-grid._100 {
      max-width: none;
    }

    .team-1 {
      min-height: auto;
      padding: 50px 19px 25px 20px;
    }

    .legal-wrapper {
      padding: 0px;
      border-style: none;
    }

    .big-social-wrapper {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .big-social-wrapper.left {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .social-circle {
      margin-bottom: 10px;
    }

    .industry-grid {
      justify-items: center;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      text-align: center;
    }

    .blog-page-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }

    .modal-centering {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }

    .list-bullet {
      margin-bottom: 10px;
      padding-top: 20px;
    }

    .list-icon {
      width: 25px;
      margin-right: 10px;
    }

    .custom-radio {
      background-position: 95% 50%;
    }

    .custom-radio.complicated {
      background-position: 94% 25px;
    }

    .form-fields-grid {
      grid-row-gap: 0px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .custom-checkbox-label {
      font-size: 15px;
      line-height: 15px;
    }

    .custom-input-group {
      -ms-grid-columns: 1fr 0.75fr;
      grid-template-columns: 1fr 0.75fr;
    }

    .body {
      font-size: 12px;
    }

    .subhead-1 {
      font-size: 21px;
      line-height: 28px;
    }

    ._90 {
      width: auto;
    }

    .bottom-cards-grid {
      grid-row-gap: 16px;
    }

    .bottom-card {
      min-height: 500px;
      padding: 50px 20px;
      border-radius: 20px;
    }

    .services-grid {
      grid-row-gap: 30px;
    }

    .form-modal {
      margin-top: 0px;
      padding: 0px 20px;
    }

    .form-section-wrapper {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .custom-checkbox-2 {
      background-position: 94% 50%;
    }

    .custom-checkbox-2.w--redirected-checked {
      background-position: 94% 50%;
    }

    .custom-checkbox-2.complicated {
      background-position: 94% 25px;
    }

    .custom-tab-block {
      margin-right: 0px;
      margin-bottom: 10px;
      margin-left: 0px;
    }

    .notification-wrapper {
      padding: 30px 20px 15px;
    }

    .notification-wrapper.summary {
      margin-right: -20px;
      margin-left: -20px;
      padding: 40px 20px 60px;
      border-radius: 0px;
    }

    .signup-tool-title {
      z-index: 2;
    }

    .ui-widget-content {
      background-color: #e5e5e5;
    }

    .field-label {
      font-size: 18px;
      line-height: 1.3;
    }

    .hidden-content {
      display: none;
    }

    .dimmed.desktop {
      display: none;
    }

    .form-name {
      font-size: 18px;
      line-height: 1.3;
    }

    .form-name.desktop {
      display: none;
    }

    .form-name.mobile {
      display: block;
    }

    .form-price {
      font-size: 18px;
    }

    .modal-nav-wrapper {
      padding: 20px 0px;
    }

    .back-1 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .form-submit-grid {
      grid-row-gap: 20px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .country-list {
      -webkit-column-count: 2;
      column-count: 2;
    }

    .voice {
      -webkit-transform: translate(-29px, 0px);
      -ms-transform: translate(-29px, 0px);
      transform: translate(-29px, 0px);
    }

    .cad-image.shift-left {
      -webkit-transform: translate(-30px, 0px);
      -ms-transform: translate(-30px, 0px);
      transform: translate(-30px, 0px);
    }

    .feature-image.stretch.with-bg {
      padding: 20px;
    }

    .components-grid-style {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .components-grid-style.reverse {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .sticky-image {
      position: -webkit-sticky;
      position: sticky;
      top: 30px;
      z-index: 999;
      margin: 0px -20px;
      padding: 20px 20px 50px;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(84%, #1e1919), to(rgba(30, 25, 25, 0)));
      background-image: linear-gradient(180deg, #1e1919 84%, rgba(30, 25, 25, 0));
    }

    .sticky-text {
      position: relative;
    }

    .more-features-grid.shift-right {
      margin-bottom: 0px;
      grid-row-gap: 0px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .more-features-grid.shift-left {
      margin-bottom: 0px;
      grid-row-gap: 0px;
    }

    .feature-card {
      padding: 9% 0% 8%;
      border-top: 1px solid hsla(0, 0%, 100%, 0.1);
      border-radius: 0px;
      background-color: transparent;
    }

    .more-features {
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    ._70--80--100 {
      max-width: 100%;
    }

    .more-features-icon {
      margin-right: 15px;
    }

    .animation-wrapper {
      width: 100%;
      height: 170px;
      padding: 30px 5%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .animation-wrapper.desktop {
      display: none;
    }

    .animation-wrapper.mobile {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .question-text {
      margin-bottom: 8px;
      padding-right: 20px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 100%;
      -ms-flex: 0 100%;
      flex: 0 100%;
    }

    .accordion-body.js-accordion-body {
      margin-top: 20px;
      padding-top: 10px;
      padding-right: 0px;
      padding-left: 0px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .accordion__item {
      margin-bottom: 0px;
      padding-right: 5%;
      padding-bottom: 0px;
      padding-left: 5%;
    }

    .accordion__item.js-accordion-item {
      margin-bottom: 0px;
      padding-right: 3%;
      padding-left: 3%;
    }

    .acordion-grid._100 {
      margin-left: 0px;
    }

    .accordion-right-block {
      padding-right: 5%;
      padding-left: 5%;
    }

    .accordion-right-block.red {
      width: auto;
      height: auto;
    }

    .accordion-right-block.apricot {
      width: auto;
      height: auto;
    }

    .accordion-right-block.salad {
      padding-right: 5%;
      padding-left: 5%;
    }

    .accordion-image {
      width: 100%;
    }

    .accordion-image-overlay.small {
      box-shadow: inset 0 0 10px 10px #fdfbfd;
    }

    .highlighted-text {
      font-size: 30px;
      line-height: 34px;
    }

    .feature-item {
      width: 100%;
      padding-right: 10%;
    }

    .accordion-item-mobile {
      margin-bottom: 0px;
      padding-right: 5%;
      padding-left: 5%;
    }

    .body-3 {
      font-size: 14px;
    }

    .hero-image-wrapper {
      background-position: 63% 50%;
    }

    .specs-content {
      grid-row-gap: 20px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .upper-nav_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .whitepaper-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .custom-nav-logo {
      width: 80px;
    }

    .feature-grid.with-image {
      justify-items: center;
      grid-row-gap: 60px;
    }

    .feature-grid.with-image.left {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }

    .careers-content-greed {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
  }

  #email.w-node-_6cdab0ae-5072-41e4-4e90-675250f93954-aa03872b {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #Email.w-node-_7efb4836-59c1-4a54-2678-7f50065e3f8e-065e3f45 {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f50-065e3f45 {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
  }

  #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f63-065e3f45 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f7a-065e3f45 {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
  }

  #w-node-_28d276b3-2ac2-2461-97cc-c72003abe79a-03abe78e {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_04153e98-6442-d1f3-e2df-2771c0b7fea1-6e03872c {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_747d24e4-141f-4a7c-c0d0-ae92f97a936d-f97a9367 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_374bac8a-a6bf-c7e0-f9d4-f67df6184178-86038733 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #email.w-node-a0e9e859-e3e9-d7e1-10a4-7525a3b672c4-61038751 {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #email.w-node-_7a127099-c3a3-a155-82c7-ca2cce715cb1-bf03875a {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #email.w-node-_4b97a856-4956-a3f4-6dd2-bfe7ce5b3cb2-a503875b {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-_00b96ecd-b74a-99b2-1bef-bea2ec86a13d-db03875c {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_913f270d-80c1-0f57-9805-914125731b45-f803875d {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #email.w-node-_0551e308-37d7-7b36-30cd-d09de6d61de9-5803875e {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #email.w-node-_92023296-dd8a-c73e-999d-7c7722e928d7-cf03875f {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-_7051e000-2439-bc97-70c1-497aa9f8926e-cf03875f {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b564621a-ecbf-2630-e752-4277ccc073b8-510387a1 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-cc669d20-d0ba-deaf-d604-d2553f7ad40f-510387a1 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_79c40253-0109-6438-a764-0bdf46897e3d-510387a1 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_83296f70-259d-1fbc-c10b-687f20cefebf-510387a1 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-ef6f93a9-5497-e6e5-8819-1a8de1076cdc-510387a1 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #email.w-node-_6cdab0ae-5072-41e4-4e90-675250f93954-ec0387af {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-c34cf18a-4c1b-03f3-1663-c7da3b1dcc92-5d0387b7 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_7a3ed617-5e07-e316-6877-9ebb0dd79bac-5d0387b7 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-b1a9245f-1431-d399-1410-9fd7499218fa-5d0387b7 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_38b95c9c-0761-e6e4-837c-8a76542a48a2-8a0387bc {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_723152a8-1b81-0f13-cd91-fb63457ca76b-e80387bf {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-a52ed376-34c8-ce4d-e002-6fe419b71864-e80387bf {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b6b8abd7-4404-cf57-914b-56eeeecc6fdb-e80387bf {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_5a038fc2-1cda-01bc-3393-a351d9a71b67-600387c0 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-_59c6c6a9-73b5-21a7-d7db-5448cbd73424-600387c0 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_09121a5a-6179-a771-8227-c7940f5f161a-600387c0 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_719dddca-7f3a-4ce8-2d9a-6d363b709e1a-600387c0 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_788d6424-0435-ac0c-0b44-cc3a94642944-600387c0 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_1567eae3-b27f-220d-9884-fd0e4706734a-600387c0 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
  }

  #w-node-_1567eae3-b27f-220d-9884-fd0e4706734f-600387c0 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_38b95c9c-0761-e6e4-837c-8a76542a48a2-290387c3 {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_048040a7-31a9-b22b-037b-6ad007a41a12-620387c4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #email.w-node-_6cdab0ae-5072-41e4-4e90-675250f93954-890387ca {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794b4-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794bb-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794be-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794c7-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794da-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794e4-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794e7-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794f0-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794fa-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279501-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279515-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279520-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927952a-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927952d-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279536-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279540-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279548-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279552-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279555-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279573-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279590-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927959a-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795a1-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795a4-640387d4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795ac-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b2-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b5-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b9-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795bc-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795c0-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795c3-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795c8-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795cb-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795cf-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795d2-640387d4 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795e5-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795ec-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795f3-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795fc-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279606-640387d4 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_5c1083c0-c04b-42f7-6bd5-a8f0a46008ff-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_35b00ab2-b6bc-ae32-6482-daa5047d5802-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-aee717dd-023f-b54c-a1fc-b87cade50274-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_99cd17a2-fcdd-2981-589a-fec6dac0903a-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_51845c53-38f8-2030-ce29-09d721cee191-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_5cf78453-5591-3078-f773-7ccc4620f48f-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-ffa47791-1137-fdd1-e217-bfe5e90fa6d5-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0b2ad1a8-7c7d-6d1f-fa26-2b9203432aed-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_38f2dfaf-f7a5-e2b5-5a07-8a318272120d-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-c4ad7db0-a36a-3eb7-fae3-73f4a295231e-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_12327585-3a2d-521a-50bf-ef87ce45cf57-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_32ac3a21-c7d5-0855-8950-416971255b4e-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_9d8dd9d8-b30d-5b57-88fe-f4718cd554c8-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b167f886-7f37-1423-1e24-325e9d896da8-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baec3-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baecd-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_8956e612-1118-b67c-f284-4b814083a99e-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-a7cd2960-f552-c206-acbc-8420226c752b-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_52eb43b0-e116-b62d-91e5-59696a7ca501-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_4f3c177b-e3ad-2010-08ab-cee76f145e82-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-fc1908ee-cffc-55fd-1126-10b9c2867bf5-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_3f72ee83-4948-0f8a-29d5-55864e28ec2c-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_04ded95e-f690-9f49-74ad-922a1fcd95cd-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-ce7458fe-4cbd-ab06-7bfc-b0b44d77c68f-630387d5 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-ffac3a93-1a6b-af29-2c4a-6aab72d35724-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_893b4f00-19a2-5735-02d5-fdc86e49fc24-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_9b4ff82a-af5a-66fd-aca1-12087abfce24-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_15711831-fcde-5fac-fb1b-9d500380668e-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_704df142-c8e3-072d-b36b-9487c6f5b7e6-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_9a5ec9d0-75f7-016d-6bdb-e8d84c2b3f69-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_9a5ec9d0-75f7-016d-6bdb-e8d84c2b3f6c-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e117a832-da22-a099-356c-e4980f9c8481-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-e117a832-da22-a099-356c-e4980f9c8484-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-a8426415-bd65-edc8-aed0-f7bd42e58873-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-a8426415-bd65-edc8-aed0-f7bd42e58876-630387d5 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-af35e1be-5732-08d2-76b6-83684f8340dd-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-f274f387-db2f-60a0-208a-7dd546028222-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-f9875a1e-8b9f-4c70-d127-2946411b79ab-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_4926e3c2-c1b9-b7f6-b42d-cfb1dea28798-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_3aa0e0c3-b0c9-ee14-8ae4-c2e5b3b5db81-630387d5 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_5e3cd63c-31c3-75a5-67a4-244d329077cb-2a0387d9 {
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-e1aa61bd-e9f8-7c2a-2eba-0c9c6fec98e5-2a0387d9 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-b93258ad-96e9-aa90-b489-b52399b43a30-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_90d583af-f328-8dbf-6979-0df2cdf9340b-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-a2840e37-9487-4531-c233-ee4bae2d05d9-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_3e9eced5-d999-5d55-014d-cfbf1d462f61-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6b686355-7af5-f8f1-bcd0-3f97310386ca-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6b686355-7af5-f8f1-bcd0-3f97310386cc-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6b686355-7af5-f8f1-bcd0-3f97310386d7-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_6b686355-7af5-f8f1-bcd0-3f97310386d8-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6cf13081-316d-904c-3d9d-a5d0e05c9499-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6cf13081-316d-904c-3d9d-a5d0e05c949b-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_6cf13081-316d-904c-3d9d-a5d0e05c94a6-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_6cf13081-316d-904c-3d9d-a5d0e05c94a7-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_31f5cc94-d859-903a-2294-a050a4853a20-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_31f5cc94-d859-903a-2294-a050a4853a22-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_31f5cc94-d859-903a-2294-a050a4853a2d-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_31f5cc94-d859-903a-2294-a050a4853a2e-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3a0f024c-5d96-2ead-59b1-8d6fa73cb024-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3a0f024c-5d96-2ead-59b1-8d6fa73cb026-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_3a0f024c-5d96-2ead-59b1-8d6fa73cb031-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_3a0f024c-5d96-2ead-59b1-8d6fa73cb032-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d4be23a1-c5d7-da42-dd43-294332e4b5c7-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d4be23a1-c5d7-da42-dd43-294332e4b5c9-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d4be23a1-c5d7-da42-dd43-294332e4b5d4-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-d4be23a1-c5d7-da42-dd43-294332e4b5d5-992848c9 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  @media screen and (min-width: 1920px) {
    #w-node-_59c6c6a9-73b5-21a7-d7db-5448cbd73424-600387c0 {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
    }

    #w-node-_1567eae3-b27f-220d-9884-fd0e4706734f-600387c0 {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
    }
  }

  @media screen and (max-width: 991px) {
    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f48-065e3f45 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f50-065e3f45 {
      -ms-grid-column: 2;
      grid-column-start: 2;
      -ms-grid-column-span: 2;
      grid-column-end: 4;
    }

    #w-node-b564621a-ecbf-2630-e752-4277ccc073b8-510387a1 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }

    #w-node-ff342cf5-264d-a83f-07b6-095137d0d392-5d0387b7 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_8f0a9faa-6835-c3f3-eab4-50febffc4dc2-5d0387b7 {
      -webkit-box-ordinal-group: 10000;
      -webkit-order: 9999;
      -ms-flex-order: 9999;
      order: 9999;
    }

    #w-node-b1a9245f-1431-d399-1410-9fd7499218fa-5d0387b7 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }

    #w-node-_723152a8-1b81-0f13-cd91-fb63457ca76b-e80387bf {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
    }

    #w-node-ca03570b-6189-159d-7a37-2d9de0d1b0f5-e80387bf {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
    }

    #w-node-ca03570b-6189-159d-7a37-2d9de0d1b100-e80387bf {
      -ms-grid-column-align: center;
      justify-self: center;
    }

    #w-node-_145c075f-756e-7e6c-15d3-df675b4ba675-600387c0 {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
    }

    #w-node-_145c075f-756e-7e6c-15d3-df675b4ba680-600387c0 {
      -ms-grid-column-align: center;
      justify-self: center;
    }

    #w-node-_145c075f-756e-7e6c-15d3-df675b4ba68b-600387c0 {
      -ms-grid-column-align: center;
      justify-self: center;
    }

    #w-node-_145c075f-756e-7e6c-15d3-df675b4ba6a3-600387c0 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_5e3cd63c-31c3-75a5-67a4-244d329077cb-2a0387d9 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }
  }

  @media screen and (max-width: 767px) {
    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f50-065e3f45 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f7a-065e3f45 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_04153e98-6442-d1f3-e2df-2771c0b7fea1-6e03872c {
      -webkit-box-ordinal-group: 10000;
      -webkit-order: 9999;
      -ms-flex-order: 9999;
      order: 9999;
    }

    #w-node-_747d24e4-141f-4a7c-c0d0-ae92f97a936d-f97a9367 {
      -ms-grid-column-align: center;
      justify-self: center;
    }

    #w-node-_40cecc36-697e-0030-9158-43a7365cdb38-86038733 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_009af45f-7c2c-9357-61d7-8e79cfe8544e-86038733 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_9ec65e4c-d743-1274-58c7-eb52be8f7b44-86038733 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_374bac8a-a6bf-c7e0-f9d4-f67df6184178-86038733 {
      -ms-grid-column-align: center;
      justify-self: center;
    }

    #w-node-_00b96ecd-b74a-99b2-1bef-bea2ec86a13d-db03875c {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_913f270d-80c1-0f57-9805-914125731b45-f803875d {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_7051e000-2439-bc97-70c1-497aa9f8926e-cf03875f {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-abda8eda-e2ef-7626-e500-c2662f8086f2-880387c8 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_12a09304-5633-b47f-6a56-a962f394d206-880387c8 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }
  }

  @media screen and (max-width: 479px) {
    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f48-065e3f45 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }

    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f50-065e3f45 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }

    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f63-065e3f45 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
    }

    #w-node-_7efb4836-59c1-4a54-2678-7f50065e3f7a-065e3f45 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_8f0a9faa-6835-c3f3-eab4-50febffc4dc2-5d0387b7 {
      -webkit-box-ordinal-group: -9998;
      -webkit-order: -9999;
      -ms-flex-order: -9999;
      order: -9999;
    }

    #w-node-_38b95c9c-0761-e6e4-837c-8a76542a48a2-8a0387bc {
      -ms-grid-column-align: stretch;
      justify-self: stretch;
    }

    #w-node-d23f784b-5d11-ba64-bd19-b73e6f85d3fd-6d0387be {
      -webkit-box-ordinal-group: 10000;
      -webkit-order: 9999;
      -ms-flex-order: 9999;
      order: 9999;
    }

    #w-node-_38b95c9c-0761-e6e4-837c-8a76542a48a2-290387c3 {
      -ms-grid-column-align: stretch;
      justify-self: stretch;
    }

    #w-node-_048040a7-31a9-b22b-037b-6ad007a41a12-620387c4 {
      -ms-grid-column-align: stretch;
      justify-self: stretch;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794b0-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794b7-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794be-640387d4 {
      -ms-grid-column-span: 2;
      grid-column-end: 3;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      -ms-grid-row: 2;
      grid-row-start: 2;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794c7-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794d4-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794d7-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794ea-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794ed-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794f4-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794f7-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292794fe-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927950f-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279512-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927951a-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927951d-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279530-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279533-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927953a-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927953d-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279558-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927955b-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927955f-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279562-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279566-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279569-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927957b-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927957e-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279582-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279585-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279589-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927958c-640387d4 {
      -ms-grid-column-align: end;
      justify-self: end;
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279590-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279593-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca129279597-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca12927959a-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795a9-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795ac-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b2-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b5-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795b9-640387d4 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795bc-640387d4 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795c3-640387d4 {
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795cb-640387d4 {
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795cf-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_0b0ec055-6ddf-05c3-c59f-0ca1292795d2-640387d4 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-_4258db9c-bb14-3013-7028-805aa3e59014-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_35b00ab2-b6bc-ae32-6482-daa5047d57fe-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-aee717dd-023f-b54c-a1fc-b87cade50274-630387d5 {
      -ms-grid-column-span: 2;
      grid-column-end: 3;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      -ms-grid-row: 2;
      grid-row-start: 2;
    }

    #w-node-_99cd17a2-fcdd-2981-589a-fec6dac0903a-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }

    #w-node-_8637495f-93d5-67c5-63f1-ca6215f8dfa4-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-be0d7d54-d91d-b4f1-33d3-0573fc81cbfb-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_15639fe9-a03b-ecad-811e-3ec051824c89-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-ca5ae025-cc55-6925-0a77-e7514ea6b1d6-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_38f2dfaf-f7a5-e2b5-5a07-8a3182721207-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_38f2dfaf-f7a5-e2b5-5a07-8a318272120a-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-c4ad7db0-a36a-3eb7-fae3-73f4a295231b-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_12327585-3a2d-521a-50bf-ef87ce45cf51-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_12327585-3a2d-521a-50bf-ef87ce45cf54-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_32ac3a21-c7d5-0855-8950-416971255b48-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_32ac3a21-c7d5-0855-8950-416971255b4b-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baebd-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baec0-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baec7-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-cd11a4d6-57d5-8b75-4c13-636e5e2baeca-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-b0277039-37e1-a0df-34f1-baa53900648d-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_55da880a-5de4-2180-1876-74cda164e262-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-d9a92f90-bf33-d5e3-dc96-ee7b94bfbbf1-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-d9a92f90-bf33-d5e3-dc96-ee7b94bfbbf4-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-a4f8726c-0542-897a-e793-b8a29059c34a-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-a4f8726c-0542-897a-e793-b8a29059c34d-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_7a7b640e-a44e-fc22-9021-e0399d653726-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_987bb877-3d7f-5a68-a54c-a4f3bea73f97-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_188747a0-13dd-0e8c-39b9-c2024d1ded2b-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-ad66676a-0929-282f-398b-496ed218e6c4-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-c6b7f654-c049-01d3-7207-9484feb97d81-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-e978eed6-a475-19bc-8fb2-a553287d983d-630387d5 {
      -ms-grid-column-align: end;
      justify-self: end;
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
    }

    #w-node-fc1908ee-cffc-55fd-1126-10b9c2867bf5-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-ada1da21-e53c-d7dd-544a-5d4e1eb84e37-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_18d8a311-877f-8e2a-776d-1c28f2cce2f2-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_3f72ee83-4948-0f8a-29d5-55864e28ec2c-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-_04ded95e-f690-9f49-74ad-922a1fcd95d7-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-ffac3a93-1a6b-af29-2c4a-6aab72d35724-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-_893b4f00-19a2-5735-02d5-fdc86e49fc24-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_9b4ff82a-af5a-66fd-aca1-12087abfce24-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_15711831-fcde-5fac-fb1b-9d500380668e-630387d5 {
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-_704df142-c8e3-072d-b36b-9487c6f5b7e6-630387d5 {
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-_9a5ec9d0-75f7-016d-6bdb-e8d84c2b3f6c-630387d5 {
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-e117a832-da22-a099-356c-e4980f9c8484-630387d5 {
      -ms-grid-column-align: end;
      justify-self: end;
    }

    #w-node-a8426415-bd65-edc8-aed0-f7bd42e58873-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }

    #w-node-a8426415-bd65-edc8-aed0-f7bd42e58876-630387d5 {
      -ms-grid-column: span 3;
      grid-column-start: span 3;
      -ms-grid-column-span: 3;
      grid-column-end: span 3;
    }

    #w-node-a2840e37-9487-4531-c233-ee4bae2d05d9-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_6b686355-7af5-f8f1-bcd0-3f97310386d7-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_6cf13081-316d-904c-3d9d-a5d0e05c94a6-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_31f5cc94-d859-903a-2294-a050a4853a2d-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-_3a0f024c-5d96-2ead-59b1-8d6fa73cb031-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }

    #w-node-d4be23a1-c5d7-da42-dd43-294332e4b5d4-992848c9 {
      -ms-grid-column-align: start;
      justify-self: start;
    }
  }
}


@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-ExtraBold.woff2') format('woff2'), url('../fonts/Manrope-Bold.woff2') format('woff2'), url('../fonts/Manrope-Bold.woff') format('woff'), url('../fonts/Manrope-ExtraBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-ExtraLight.woff2') format('woff2'), url('../fonts/Manrope-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Light.woff2') format('woff2'), url('../fonts/Manrope-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Medium.woff2') format('woff2'), url('../fonts/Manrope-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Regular.woff2') format('woff2'), url('../fonts/Manrope-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-SemiBold.woff2') format('woff2'), url('../fonts/Manrope-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('../fonts/fa-solid-900.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
